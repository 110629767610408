<template>
  <!-- star -->

  <div class="youjiaopage_wrap">
    <nav2></nav2>
    <banner2 :bannerH="h1" :bannerImg="Img1" style="z-index: 999;"></banner2>
    <!-- 面包屑 -->
    <div class="Crumbs">
      <div style="font-size: 20px;">当前位置：产品介绍><span>c7</span></div>
    </div>
    <!-- 金融板块 -->
    <div class="Money">
      <div class="Money_cont" style="margin-bottom: 20px;">
        <!-- <h1>c7板块</h1> -->
        <div class="Money_cont_top">
          <p>
            历经三个月，烟台小和出行科技有限公司设计师同工程师携手合作，不断测试，研制出更适合亚洲人骑行的FM-C7（国内版）电动滑板车。

简介：FM-C7 ，靓丽车身，醒目的车体和示廓灯，能够使其更具辨识度，减少安全隐患。车体防火阻燃，前后双减震系统，600w的强劲电机，能适应更多复杂、更颠簸的路况，车速可调节，配备强大刹车系统，前轮鼓刹，后轮电刹，即停即走，安全便捷出行。10英寸免维修橡胶轮胎，回弹性能好，更好缓冲颠簸，给您稳稳的舒适感。FM-C7使用的是48V12Ah大容量优质锂离子电池，
持续的高倍率电流输出能力，提供45km超长里程，无论是户外游玩还是城市通勤，妥妥够。
          </p>
          <!-- <p>
            历经三个月，烟台小和出行设计师同工程师携手合作，不断测试，研制出更适合亚洲
            人骑行的FM-C7（国内版）电动滑板车。
            简介：FM-C7 ，靓丽车身，醒目的车体和示廓灯，能够使其更具辨识度，减少安全隐患。车体防火阻燃，前后双减震系统，600w的强劲电机，车速可调节，配备强大刹车系统，前轮鼓刹，后轮电刹，即停即走，10英寸免维修橡胶轮胎，
            无论是户外游玩还是城市通勤，妥妥够。优质锂电池，提供长达40km的续航里程，安全便捷出行。
          </p> -->
          <img style="width: 550px;height: 320px;" v-lazy="require('../assets/img/car.jpeg')" alt="" lazy/>
        </div>
        <div class="Money_cont_c">
          <div class="Money_cont_c_img">
            <img v-lazy="require('../assets/img/list2.png')" alt="" lazy
            style="width: 300px;height: 360px;margin-top: 0px;"/>
            <p>融资主频</p>
          </div>
          <div class="Money_cont_c_text">
            <!-- <p>
              金融板块依托强大的产业背景、雄厚的资金实力、丰富的政企资源以及各领域众多资深合作伙伴，借助灵活的资本运作方式以及集团采购、整体打包的资源优势，通过融资租赁、直接租赁、售后回租、厂商租赁、风险租赁等业务方式，为政企客户提供全方位、专业化、一站式的金融服务。
            </p> -->
            <div class="Money_cont_c_text_b">
              <!-- <span>01</span> -->
              <p>
                超长续航大电池：FM-C7使用的是48V12Ah大容量优质锂离子电池，持续的高倍率电流输出能力，提供40km超长里程
              </p>
            </div>
            <div class="Money_cont_c_text_b">
              <!-- <span>01</span> -->
              <p>
                超大功率电机：FM-C7使用的是600W超大功率电机，最大爬坡度可达20°，能适应更多复杂，更颠簸的路况。
              </p>
            </div>
            <div class="Money_cont_c_text_b">
              <!-- <span>01</span> -->
              <p>
                超高颜值车身：FM-C7独特的外观标识，颜色靓丽，为滑板车注入了新的活力。
              </p>
            </div>
            <div class="Money_cont_c_text_b">
              <!-- <span>01</span> -->
              <p>
                减震系统：FM-C7精心调校的弹簧减震器，可以有效缓冲复杂地形带来的颠簸，你可以驾驶它在崎岖的路面上骑行，最大限度地提高您的乐趣，骑行更加舒适。
              </p>
            </div>
            <div class="Money_cont_c_text_b">
              <!-- <span>01</span> -->
             <p>
              免维修橡胶轮胎：FM-C7拥有10英寸免维修橡胶轮胎，且回弹性能好，更好缓冲颠簸，给您稳稳的舒适感，高速过减速带，轻巧越过不停车，坑洼路沙子路，颠簸手不麻。
             </p>
            </div>
            <div class="Money_cont_c_text_b">
              灯光系统：FM-C7采用了LED灯，前面除了主大灯，后面尾翼还有警示灯，能满足您的夜间行驶，您夜间行驶更加安全。
              <!-- <span>01</span> -->
             <p>

             </p>
            </div>
          </div>
        </div>
        <!-- <div class="Money_cont_b">
          <div class="Money_cont_b_item1">
            <div class="Money_cont_b_img">
              <img src="../assets/img/jinrong/编组 2(1).png" alt="" />
              <p>融资主频</p>
            </div>
            <div class="Money_cont_b_text">
              <p>
                信托即信用委托，是指委托人基于对受托人的信任，将其财产权委托给受托人，由受托人按委托人的意愿以自己的名义，为受益人的利益或者特定目的进行管理或者处分的行为。<br />
                金融板块始终紧跟国家发展战略，通过资金信托、动产信托、不动产信托、有价证券信托等多种形式，经营企业资产的重组、购并及项目融资、公司理财等，拓展全新业务增长极，推动业务回归本源，打造出金融助力实体经济发展的信托范本。
              </p>
            </div>
          </div>
          <div class="Money_cont_b_item2">
            <div class="Money_cont_b_item2_img">
              <img src="../assets/img/jinrong/编组 2(1).png" alt="" />
              <p>融资主频</p>
            </div>
            <div class="Money_cont_b_item2_text">
              <p>
                信托即信用委托，是指委托人基于对受托人的信任，将其财产权委托给受托人，由受托人按委托人的意愿以自己的名义，为受益人的利益或者特定目的进行管理或者处分的行为。<br />
                金融板块始终紧跟国家发展战略，通过资金信托、动产信托、不动产信托、有价证券信托等多种形式，经营企业资产的重组、购并及项目融资、公司理财等，拓展全新业务增长极，推动业务回归本源，打造出金融助力实体经济发展的信托范本。
              </p>
            </div>
          </div>
          <div class="Money_cont_b_item3 Money_cont_b_item4">
            <div class="Money_cont_b_item3_img">
              <img src="../assets/img/jinrong/编组 2.png" alt="" />
              <p>融资主频</p>
            </div>
            <div class="Money_cont_b_item3_text">
              <p>
                信托即信用委托，是指委托人基于对受托人的信任，将其财产权委托给受托人，由受托人按委托人的意愿以自己的名义，为受益人的利益或者特定目的进行管理或者处分的行为。<br />
                金融板块始终紧跟国家发展战略，通过资金信托、动产信托、不动产信托、有价证券信托等多种形式，经营企业资产的重组、购并及项目融资、公司理财等，拓展全新业务增长极，推动业务回归本源，打造出金融助力实体经济发展的信托范本。
              </p>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <footer2></footer2>

    <!-- end -->
  </div>
</template>


<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav2.vue";
export default {
  name: "keji",
  components: {
    footer2,
    nav2,
    banner2,
  },
  data() {
    return {
      h1: "公司产品",
      Img1: require("../assets/img/banner1.png"),
    };
  },
  methods: {},
};
</script>
<style scoped>
@charset "utf-8";

/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}

/*去内外边距*/
html,
body {
  font-family: "微软雅黑", "宋体", Arail, Tabhoma;
  text-align: left;
}

/*字体 文本居左*/
ul {
  list-style: none;
}

/*去ul li 小点*/
img {
  border: 0 none;
}

/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}

/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}

/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}

/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}

/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}

/*清浮动*/
a {
  color: #000000;
}

/*==========================Reset_End===========================*/

.youjiaopage_wrap {
  width: 100%;
  /* width: 1920px; */
  margin: 0 auto;
  height: 1000px;
  /* background: #f8f8f8; */
}

/* 面包屑 */
.Crumbs {
  width: 1200px;
  height: 46px;
  margin: 0 auto;
}

.Crumbs {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}

.Crumbs div {
  line-height: 46px;
}

.Crumbs span {
  color: #000000;
}

/* 金融 */
.Money_cont {
  width: 1200px;
  margin: 0 auto;
}

.Money_cont>h1 {
  margin: 65px 0 45px 0;
  text-align: center;
}

.Money_cont_top {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  /* background: #f8f8f8; */
}

.Money_cont_top>p {
  width: 600px;
  height: 360px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  line-height: 40px;
}

/* 中 */
.Money_cont_c {
  display: flex;
  margin-top: 80px;
  width: 1200px;
  height: 440px;
}

.Money_cont_c_img {
  text-align: center;

  width: 322px;
  height: 440px;
  /* background-image: url("../assets/img/jinrong/摄图网_500476224_banner_看得见的未来（非企业商用）.png"); */
}

.Money_cont_c_img p {
  margin-top: 32px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 33px;
}

.Money_cont_c_img img {
  margin-top: 138px;
}

.Money_cont_c_text {
  width: 878px;
  height: 360px;
  /* height: 440px; */
  /* background: #f8f8f8; */
}

.Money_cont_c_text>p {
  width: 769px;
  height: 96px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  line-height: 32px;
  margin: 40px 0 40px 40px;
}

.Money_cont_c_text_b {
  margin-bottom: 30px;
  margin-left: 40px;
}

.Money_cont_c_text_b span {
  color: blue;
}

.Money_cont_b_item1 {
  display: flex;
  margin-top: 40px;
}

.Money_cont_b_img {
  text-align: center;
  /* background-image: url("../assets/img/jinrong/摄图网_500476224_banner_看得见的未来（非企业商用）.png"); */
  width: 322px;
  height: 300px;
}

.Money_cont_b_img p {
  margin-top: 32px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 33px;
}

.Money_cont_b_img img {
  margin-top: 67px;
}

.Money_cont_b_text {
  width: 878px;
  height: 300px;
  background: #f8f8f8;
}

.Money_cont_b_text p {
  margin: 50px 0 0 40px;
  width: 769px;
  height: 200px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  line-height: 40px;
}

/* item2 */
.Money_cont_b_item2 {
  display: flex;
  margin-top: 40px;
}

.Money_cont_b_item2_img {
  text-align: center;
  background-image: url("../assets/img/ner.png");
  width: 322px;
  height: 300px;
}

.Money_cont_b_item2_img p {
  margin-top: 32px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 33px;
}

.Money_cont_b_item2_img img {
  margin-top: 67px;
}

.Money_cont_b_item2_text {
  width: 878px;
  height: 300px;
  background: #f8f8f8;
}

.Money_cont_b_item2_text p {
  margin: 50px 0 0 40px;
  width: 769px;
  height: 200px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  line-height: 40px;
}

/* item3 */
.Money_cont_b_item3 {
  display: flex;

  margin: 40px 0 80px 0;
}

/* .Money_cont_b_item3_img {
  text-align: center;
  background-image: url("../assets/img/jinrong/摄图网_401914220_banner_3D创意金融（非企业商用）.png");
  width: 322px;
  height: 300px;
} */

.Money_cont_b_item3_img p {
  margin-top: 32px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 33px;
}

.Money_cont_b_item3_img img {
  margin-top: 67px;
}

.Money_cont_b_item3_text {
  width: 878px;
  height: 300px;
  background: #f8f8f8;
}

.Money_cont_b_item3_text p {
  margin: 50px 0 0 40px;
  width: 769px;
  height: 200px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  line-height: 40px;
}</style>


